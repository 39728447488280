import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Applying for your first credit card can be a daunting task, especially when you have a thin credit file. Demonstrating your ability to pay your debts is key to successful credit card applications. In this article, we want to give you a quick summary of what to expect when applying for your first credit card.`}</p>
    <h2>{`Check Your Credit Score First`}</h2>
    <p>{`Before applying for your first credit card, you should always get a sense of your current credit standing by checking your credit score. We've written a detailed article on ways to check your credit score for free.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Related: `}<a parentName="p" {...{
          "href": "https://credit101.net/score/four-ways-to-check-your-credit-score"
        }}>{`4 Ways to Check Your Credit Score`}</a></p>
    </blockquote>
    <h2>{`Application Strategies by Types of Applicants`}</h2>
    <p><strong parentName="p">{`Be Added as Authorized User`}</strong></p>
    <p>{`If you know someone ‒ usually a family member ‒ who's willing to add you as an authorized user to their existing credit card, take the offer. As an authorized user, you can access the primary user's credit line and build credit at the same time by demonstrating responsible use of the card. Credit card holders with bad credit scores have reported an average of 10% increase in their credit scores within 30 days from being added as an authorized user. Plus, it's unlikely for banks to deny authorized user requests as they're initiated by the primary user.`}</p>
    <p><strong parentName="p">{`Student`}</strong></p>
    <p><img parentName="p" {...{
        "src": "https://upload.wikimedia.org/wikipedia/en/thumb/a/a3/CampusLife_sm.jpg/1599px-CampusLife_sm.jpg",
        "alt": "College Students"
      }}></img></p>
    <figcaption>College Student (Photo by Amariotti/Wikipedia)</figcaption>
    <p>{`If you're a student, you're very lucky because your student status gives you more options. From the author's experience, most creditors are quite lenient with issuing student cards. With a mid-600 range credit score, the author was automatically approved for a student card.`}</p>
    <p>{`When you apply for student cards, you'll be asked to verify your student status (usually through a .edu email). These cards can offer you cash back rates on a par with many mid-tier cash back cards. Student cards also offer many student-specific benefits. The author likes the `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B00LXON8TM/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00LXON8TM&linkCode=as2&tag=kevinwang0f-20&linkId=fd717661d96b1f9c000c6366678ea2e9"
      }}>{`Discover Student Chrome`}</a>{` card particularly as it rewards students with GPAs higher than 3.0 an annual statement credit of $20. You also get free social security number theft monitoring and monthly updated FICO 8 Score with the card.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <p><strong parentName="p">{`Poor Credit`}</strong></p>
    <p><img parentName="p" {...{
        "src": "https://files.consumerfinance.gov/f/images/201702_cfpb_Oah_Blog6-credit_score.original.png",
        "alt": "Credit Score"
      }}></img></p>
    <p>{`Don't worry! You're still eligible for many credit cards. After all, credit score isn't the only thing banks use to make decisions. If you have a high income, you might be eligible for even some of the top-tier travel cards.`}</p>
    <p>{`If you think your income is not high enough, you're always eligible for a secured card, which kind of works like a debit card. Before using the card, you'll be asked to deposit a certain amount of funds (usually a percentage of your credit limit) into the account. The security deposit is refundable and will always be refunded if you do not use the full amount by statement's closing. In many cases, you can reap the same amount of cash back rewards with your secured credit card. The Discover Secured credit card is a great example as it offers the same benefits as the `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B00LXNZRVU/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00LXNZRVU&linkCode=as2&tag=kevinwang0f-20&linkId=c73e81d1bf4fb8b5188a710fff49f8b9"
      }}>{`Discover Chrome`}</a>{` card.`}</p>
    <p><strong parentName="p">{`Turns out I Have Good Credit Score`}</strong></p>
    <p>{`Well, congratulations! We recommend that you start with applying for a low-tier cash back card as lenders might still reject your application because of your thin credit file. Be aware that your income is another factor that banks consider when approving credit card applications.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`The Bottom Line`}</h2>
    <p>{`Hopefully, you now have a better understanding of your options when applying for your first credit card. We will be posting detailed reviews of some of the cards mentioned above to give you a sense of their respective pros and cons.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      